<template>
  <b-card>
    <vue-element-loading :active="loader" />
    <template #header>
      <b-button :disabled="loader" variant="warning" @click.stop="completeRate()">Complete Month Rate</b-button>
      <b-form inline class="ml-auto">
        <label class="mr-1">Month</label>
        <v-select
          v-model="selectedMonth"
          :options="monthList"
          label="name"
          value="value"
          style="min-width: 150px"
          placeholder="Select Month"
          :clearable="false"
          @option:selected="getCurrencyRate"
        ></v-select>
        <label class="mx-1">Year</label>
        <v-select v-model="selectedYear" :options="yearList" placeholder="Select Year" :clearable="false" style="min-width: 150px" @option:selected="getCurrencyRate"></v-select>
      </b-form>
    </template>
    <b-table :items="table.items" :fields="table.fields" small fixed striped>
      <template #cell(historyDate)="data">
        {{ data.item.historyDate | date }}
      </template>
      <template #cell(rate)="data">
        {{ data.item.rate | decimal }}
      </template>
      <template #cell(change)="data">
        <span v-if="data.item.change != 0" :class="data.item.change > 0 ? `text-success` : `text-danger`">
          {{ data.item.change | decimal }}
        </span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import api from "@api";

export default {
  name: `CurrencyRate`,
  data: function () {
    return {
      monthList: [
        {
          name: "January",
          value: 1,
        },
        {
          name: "February",
          value: 2,
        },
        {
          name: "March",
          value: 3,
        },
        {
          name: "April",
          value: 4,
        },
        {
          name: "May",
          value: 5,
        },
        {
          name: "June",
          value: 6,
        },
        {
          name: "July",
          value: 7,
        },
        {
          name: "August",
          value: 8,
        },
        {
          name: "September",
          value: 9,
        },
        {
          name: "October",
          value: 10,
        },
        {
          name: "November",
          value: 11,
        },
        {
          name: "December",
          value: 12,
        },
      ],
      selectedMonth: null,
      yearList: [],
      selectedYear: null,
      table: {
        items: [],
        fields: [
          { key: `historyDate`, label: `Date`, sortable: true },
          { key: `rate`, label: `Rate`, class: `text-right` },
          { key: `change`, label: `Change`, class: `text-right` },
        ],
      },
      loader: false,
    };
  },
  async mounted() {
    await this.initYear();
    await this.initMonth();
    await this.getCurrencyRate();
  },
  methods: {
    async initYear() {
      let currentYear = new Date().getFullYear();
      for (let i = currentYear; i >= 2020; i--) {
        this.yearList.push(i);
      }
      this.selectedYear = currentYear;
    },
    async initMonth() {
      let currentMonth = new Date().getMonth() + 1;
      this.selectedMonth = this.monthList.find(x => x.value == currentMonth);
    },
    async getCurrencyRate() {
      this.loader = true;
      try {
        let result = await api.get(`currency`, { month: this.selectedMonth.value, year: this.selectedYear });
        let previousRate = null;
        this.table.items = result.map(item => {
          let change = previousRate !== null ? item.rate - previousRate : 0;
          previousRate = item.rate;
          return {
            ...item,
            change: change,
          };
        });
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async completeRate() {
      this.loader = true;
      try {
        await api.post(`currency`, { month: this.selectedMonth.value, year: this.selectedYear });
        this.getCurrencyRate();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
